<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9" class="pr-2">
                <v-row no-gutters class="bg-white" v-if="location != null">
                    <v-col cols="8">
                        <v-card-title class="headline">{{ location.name }}</v-card-title>
                        <v-card-subtitle>{{ location.city }}, {{ location.state }}</v-card-subtitle>
                    </v-col>

                    <v-col cols="4" class="text-right pa-4">
                        <v-chip label small :color="location.active ? 'success' : 'error'" @click="toggleDialog = true">{{ location.active ? 'Active' : 'Inactive' }}</v-chip>
                    </v-col>

                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="3" class="pa-3">
                                <div class="title">
                                    {{ location.units.length }}
                                </div>
                                <div class="caption grey--text">
                                    Total units
                                </div>
                            </v-col>

                            <v-col cols="3" class="pa-3">
                                <div class="title">
                                    {{ totalHouses }}
                                </div>
                                <div class="caption grey--text">
                                    Houses
                                </div>
                            </v-col>

                            <v-col cols="3" class="pa-3">
                                <div class="title">
                                    {{ totalApartments }}
                                </div>
                                <div class="caption grey--text">
                                    Apartments
                                </div>
                            </v-col>

                            <v-col cols="3" class="pa-3">
                                <div class="title">
                                    {{ toMoney( location.account.balance ) }}
                                </div>
                                <div class="caption grey--text">
                                    Balance
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <div class="bg-white mt-2 py-2 px-3">
                    <v-tabs v-model="tab" @change="onTabChange">
                        <v-tab>Financials</v-tab>
                        <v-tab>Information</v-tab>
                        <v-tab>Statistics</v-tab>
                        <v-tab>Users</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <!-- Financials -->
                        <v-tab-item class="pa-2">
                            <v-alert :value="location.account.paymentsBehind >= 3 && location.active && !location.blockMissingPayment" dense type="warning">
                                This location has 3 or more late payments, consider blocking access to the app.
                            </v-alert>
                            <v-alert :value="location.blockMissingPayment" dense type="error">
                                This location is blocked due to lack of payment.
                            </v-alert>

                            <v-row no-gutter>
                                <v-col cols="3">
                                    <div class="title">{{ location.account.paymentsBehind }}</div>
                                    <div class="caption grey--text">Payments behind</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="title">{{ toMoney( location.account.monthlyPayment ) }}</div>
                                    <div class="caption grey--text">Fee</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="title">{{ location.account.frequency }}</div>
                                    <div class="caption grey--text">Frequency</div>
                                </v-col>
                                <v-col cols="3">
                                    <div class="title">{{ formatDate( location.account.nextDueDate, 'MMM DD, YYYY' ) }}</div>
                                    <div class="caption grey--text">Next due date</div>
                                </v-col>
                            </v-row>

                            <div class="my-5"></div>

                            <v-subheader class="pl-0">Actions</v-subheader>

                            <v-row no-gutters>
                                <v-btn depressed color="primary" @click="paymentDialog = true">Payment</v-btn>
                                <v-btn depressed class="mx-3" color="warning" @click="warningDialog = true">Send warning</v-btn>
                                <v-btn depressed color="error" :disabled="location.account.paymentsBehind < 3" 
                                    v-if="location.blockMissingPayment == false" @click="blockDialog = true">Block</v-btn>
                                <v-btn depressed color="success" v-else @click="blockLocation(false)">Unblock</v-btn>
                            </v-row>

                            <div class="my-5"></div>

                            <v-subheader class="pl-0">Latest payments</v-subheader>

                            <v-data-table :headers="paymentHeaders" :items="location.latestPayments">
                                <template v-slot:item.paymentDate="{ item }">
                                    {{ formatDate( item.paymentDate, 'YYYY-MM-DD @ hh:mm a' ) }}
                                </template>
                                <template v-slot:item.paidAmount="{ item }">
                                    {{ toMoney( item.paidAmount ) }}
                                </template>
                                <template v-slot:item.receiptUrl="{ item }">
                                    <v-btn depressed small :href="item.receiptUrl" target="_blank" color="primary">View</v-btn>
                                </template>
                            </v-data-table>
                        </v-tab-item>

                        <!-- Information -->
                        <v-tab-item class="pa-2">
                            <v-row no-gutters>
                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader>General Information</v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ location.adminContactName }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>Admin contact</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ toPhone( location.adminContactPhone ) }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>Admin contact phone</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ location.adminContactEmail }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>Admin contact email</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div>{{ location.addressLine1 }}</div>
                                                    <div>{{ location.addressLine2 }}</div>
                                                    <div>{{ location.city }} {{ location.state }}, {{ location.zipcode }}</div>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>Location address</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader>Financial Information</v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ availableMethods }}</v-list-item-title>
                                                <v-list-item-subtitle>Payment methods accepted</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ toMoney( location.paymentAmount ) }}</v-list-item-title>
                                                <v-list-item-subtitle>Monthly fee</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ location.paymentFrequencyId }}</v-list-item-title>
                                                <v-list-item-subtitle>Frequency</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ location.stripeCustomerId }}</v-list-item-title>
                                                <v-list-item-subtitle>Stripe customer id</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader>Working hours</v-subheader>
                                        <v-list-item v-for="(item, ix) in adminWorkingHours" :key="ix">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.workingHour }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.workingDay }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-subheader>Additional information</v-subheader>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ location.topicArn }}</v-list-item-title>
                                                <v-list-item-subtitle>Topic ARN</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="mt-4">
                                    <v-subheader>Groups</v-subheader>
                                    <v-expansion-panels accordion>
                                        <v-expansion-panel v-for="(item, ix) in location.groups" :key="ix">
                                            <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div>{{ item.description }}</div>

                                                <v-data-table :headers="groupMembersHeaders" :items="item.members">
                                                    <template v-slot:item.phone="{ item }">
                                                        {{ toPhone( item.phone ) }}
                                                    </template>
                                                    <template v-slot:item.active="{ item }">
                                                        <v-chip small :class="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
                                                    </template>
                                                </v-data-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>

                                <v-col cols="12" class="mt-4">
                                    <v-subheader>Residents</v-subheader>
                                    <v-data-table :headers="residentsHeaders" :items="location.residents" :footer-props="paginationOptions">
                                        <template v-slot:item.phone="{ item }">
                                            {{ toPhone( item.phone ) }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- Statistics -->
                        <v-tab-item>
                            <v-row no-gutters class="mt-3">
                                <v-col cols="3" offset="9">
                                    <v-select v-model="period" :items="periods" outlined solo flat dense hide-details @change="loadStatistics"></v-select>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader>Usage</v-subheader>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statistics.activeUsers }}</v-list-item-title>
                                                <v-list-item-subtitle>Active users</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statistics.newEvents }}</v-list-item-title>
                                                <v-list-item-subtitle>New Events</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader></v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statistics.communityMessages }}</v-list-item-title>
                                                <v-list-item-subtitle>Community messages</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statistics.eventResponses }}</v-list-item-title>
                                                <v-list-item-subtitle>Event responses</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>

                                <v-col cols="4">
                                    <v-list dense>
                                        <v-subheader></v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statistics.payments }}</v-list-item-title>
                                                <v-list-item-subtitle>Payments</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- Users -->
                        <v-tab-item>
                            <div class="text-right my-2">
                                <v-btn depressed small color="primary" @click="userDialog = true">Add new</v-btn>
                            </div>

                            <v-data-table :headers="usersHeaders" :items="location.users" :footer-props="paginationOptions">
                                <template v-slot:item.fullName="{ item }">
                                    {{ item.firstName }} {{ item.lastName }}
                                </template>
                                <template v-slot:item.dateCreated="{ item }">
                                    {{ formatDate( item.dateCreated ) }}
                                </template>
                                <template v-slot:item.lastLogin="{ item }">
                                    {{ formatDate( item.lastLogin, 'YYYY-MM-DD @ hh:mm a' ) }}
                                </template>
                                <template v-slot:item.locationActive="{ item }">
                                    <v-chip label small :color="isUserLocationActive( item ) ? 'success' : 'error'">{{ isUserLocationActive( item ) ? 'Active' : 'Inactive' }}</v-chip>
                                </template>
                                <template v-slot:item.edit="{ item }">
                                    <v-btn icon>
                                        <v-icon @click="editUser(item)">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-col>

            <v-col cols="3" class="bg-white">
                <v-card-title>Comments</v-card-title>
                <!-- <v-card-subtitle class="grey--text">Leave internal notes for future reference</v-card-subtitle> -->

                <div class="px-3">
                    <!-- <v-textarea outlined placeholder="Write a comment..." hide-details></v-textarea>
                    <v-btn depressed block color="primary" class="my-2">Post</v-btn> -->

                    <v-timeline dense clipped>
                        <v-timeline-item fill-dot class="white--text mb-12" color="blue-grey" large>
                            <template v-slot:icon>
                                <span>{{ user.firstName[0] + user.lastName[0] }}</span>
                            </template>
                            <v-text-field v-model="comment" hide-details flat label="Leave a comment..." solo @keydown.enter="postComment">
                            </v-text-field>

                            <v-btn class="mx-0" block depressed @click="postComment">Post</v-btn>
                        </v-timeline-item>

                        <v-slide-x-transition group>
                            <v-timeline-item v-for="event in comments" :key="event.id" class="mb-4" color="grey" small>
                                <v-row justify="space-between">
                                    <v-col cols="7" class="text-caption" v-text="event.comment"></v-col>
                                    <v-col class="text-right text-caption" cols="5" v-text="formatDate(event.date_created, 'YYYY-MM-DD @ hh:mm a')"></v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-slide-x-transition>
                    </v-timeline>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="paymentDialog" width="800">
            <v-card>
                <v-card-title>
                    Payments

                    <v-spacer></v-spacer>
                    <v-btn icon @click="paymentDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Submit payments or configure your automatic payments</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="7">
                            <div class="subtitle-1">Available payments</div>
                            <v-simple-table fixed-header height="400">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><v-icon :color="payment.applyTo.length > 0 ? 'indigo darken-4' : ''" @click="toggleSelectAll">{{ icon }}</v-icon></th>
                                            <th class="text-left">Name</th>
                                            <th class="text-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="p in location.billing.availablePayments" :key="p.id">
                                            <td><v-icon :color="isSelected(p) ? 'primary' : ''" @click="toggleItem(p)">{{ isSelected(p) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></td>
                                            <td>{{ p.dueDateMonthYear }}</td>
                                            <td class="text-right">{{ toMoney( p.amount ) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="5">
                            <div class="subtitle-1">Payment methods</div>

                            <div v-show="location.billing.cards.length > 0">
                                <template v-for="card in location.billing.cards">
                                    <v-list-item :key="card.id" class="px-0">
                                        <v-list-item-avatar tile>
                                            <img :src="getCardBrandImg(card.brand)" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>᛫᛫᛫᛫ ᛫᛫᛫᛫ ᛫᛫᛫᛫ {{ card.last4 }}</v-list-item-title>
                                            <v-list-item-subtitle>exp {{ card.exp_month }}/{{ card.exp_year }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="success" v-show="isMethodSelected(card)">mdi-check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>

                                <v-btn block depressed class="mt-3" @click="newCardForm = true" v-show="!newCardForm"><v-icon left>mdi-plus</v-icon> Add new card</v-btn>
                            </div>
                            <div v-show="location.billing.cards.length == 0 || newCardForm">
                                <div class="mt-3">Add a new credit card</div>
                                <stripe-element-card ref="stripeCard" :pk="stripePK" @token="tokenCreated" />
                                <v-btn block depressed @click="createCard" :loading="addingCard" class="mt-3" color="primary">Add card</v-btn>
                                <v-btn block depressed @click="newCardForm = false" class="mt-1" v-show="location.billing.cards.length > 0">Cancel</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed block @click="processPayment" color="primary" :loading="processingPayment" 
                        :disabled="payment.applyTo.length == 0 || payment.method == null">
                        CHARGE {{ toMoney( totalPayment ) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog" width="400">
            <v-card>
                <v-card-title>
                    Warning
                </v-card-title>
                <v-card-text>
                    Are you sure you want to send a missing payment warning message to this location administrator?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed>Yes</v-btn>
                    <v-btn depressed color="primary" @click="warningDialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="blockDialog" width="400">
            <v-card>
                <v-card-title>
                    Warning
                </v-card-title>
                <v-card-text>
                    Are you sure you want to block access to this location?
                    <br><br>
                    <strong>Important:</strong> This will block the mobile app for residents and the admin panel for administrators.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed :loading="blocking" @click="blockLocation(true)">Yes</v-btn>
                    <v-btn depressed color="primary" @click="blockDialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="toggleDialog" width="400">
            <v-card>
                <v-card-title>
                    Warning
                </v-card-title>
                <v-card-text>
                    Are you sure you want to {{ location.active ? 'deactivate' : 'activate' }} this location?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="activate">Yes</v-btn>
                    <v-btn depressed color="primary" @click="toggleDialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="userDialog" width="500">
            <v-card>
                <v-card-title>Create a new user</v-card-title>
                <v-card-subtitle>Add a new user to manage this location</v-card-subtitle>
                <v-card-text>
                    <admin-user :location-id="locationId" @saved="closeUserDialog" ref="user" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="userDialog = false">Cancel</v-btn>
                    <v-btn depressed color="primary" :loading="savingUser" @click="saveUser">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import AdminUser from '@/components/AdminUser.vue';
import moment from 'moment';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
    props: {
        locationId: { type: [String, Number], default: null },
        locationName: { type: String, default: 'N/A' },
    },
    data() {
        return {
            location        : this.cleanObject(),
            statistics      : {},
            loading         : false,
            loadingStats    : false,
            blocking        : false,

            tab             : null,
            period          : 'last7',

            paymentHeaders  : [
                { text: 'Payment', value: 'dueDateMonthYear', sortable: true },
                { text: 'Date', value: 'paymentDate', sortable: true },
                { text: 'Method', value: 'paymentMethod', sortable: true },
                { text: 'Amount', value: 'paidAmount', sortable: true },
                { text: 'Receipt', value: 'receiptUrl', sortable: true }
            ],

            groupMembersHeaders: [
                { text: 'Name', value: 'name', sortable: true },
                { text: 'Phone', value: 'phone', sortable: true },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Position', value: 'position', sortable: true },
                { text: 'Active', value: 'active', sortable: true },
            ],
            residentsHeaders: [
                { text: 'Name', value: 'fullName', sortable: true },
                { text: 'Phone', value: 'phone', sortable: true },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Unit', value: 'unitNumber', sortable: true },
                { text: 'Type', value: 'buildingType', sortable: true },
            ],
            usersHeaders: [
                { text: 'Name', value: 'fullName', sortable: true },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Date Created', value: 'dateCreated', sortable: true },
                { text: 'Last Login', value: 'lastLogin', sortable: true },
                { text: 'Active', value: 'locationActive', sortable: true },
                { text: 'Edit', value: 'edit', sortable: false },
            ],
            paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },

            periods: [
                { text: 'last 7 days', value: 'last7' }, 
                { text: 'last 30 days', value: 'last30' },
                { text: 'this month', value: 'thismonth' },
                { text: 'last month', value: 'lastmonth' },
                { text: 'year to date', value: 'yeartodate' },
                { text: 'last year', value: 'lastyear' }
            ],

            warningDialog   : false,
            blockDialog     : false,
            paymentDialog   : false,
            toggleDialog    : false,
            userDialog      : false,
            addingCard      : false,
            newCardForm     : false,
            payment         : {
                date: new Date().toISOString().substr(0, 10),
                method: null,
                confirmation: '',
                applyTo: [],
                amount: 0,
            },
            processingPayment   : false,
            savingUser      : false,

            comment         : '',
            comments        : [],
        }
    },
    computed: {
        ...mapGetters({
            user        : 'auth/user',
            stripePK    : 'settings/stripePK'
        }),
        totalHouses() {
            let houses = this.location.unitSummary.find(i => {
                return i.buildingTypeId == 1;
            });

            if( _st.isNUE( houses ) )
                return 0;

            return houses.unitQty;
        },
        totalApartments() {
            let apt = this.location.unitSummary.find(i => {
                return i.buildingTypeId == 2;
            });

            if( _st.isNUE( apt ) )
                return 0;

            return apt.unitQty;
        },
        availableMethods() {
            let methods = [];
            if( this.location.stripeActive ) methods.push('Stripe');
            if( this.location.athmovilActive ) methods.push('ATH Movil');
            if( this.location.paypalActive ) methods.push('Paypal');

            return methods.join(', ');
        },
        adminWorkingHours() {
            let hours = this.location.workingHours.filter(h => {
                return h.type == 'admin';
            });
            
            return _st.isNU( hours ) ? [] : hours;
        },
        totalAmount() {
            let total = 0;
            this.location.billing.availablePayments.forEach(p => {
                total += parseFloat( p.amount );
            });

            return total;
        },
        totalPayment() {
            let total = 0;
            this.payment.applyTo.forEach(p => {
                total += parseFloat( p.amount );
            });
            
            return total;
        },
        allPaymentsSelected() {
            return this.payment.applyTo.length === this.location.billing.availablePayments.length;
        },
        icon() {
            if (this.allPaymentsSelected) return 'mdi-close-box';
            if (this.payment.applyTo.length > 0 && this.payment.applyTo.length < this.location.billing.availablePayments.length) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/location/${this.locationId}`);
                this.loading = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.location = res.data.data;
                this.comments = this.location.comments;
                if( this.location.billing.cards.length == 1 ) {
                    this.payment.method = this.location.billing.cards[0];
                }
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        onTabChange(t) {
            if( t != 2 )
                return;

            this.loadStatistics();
        },
        async loadStatistics() {
            let api = new API();

            try {
                this.loadingStats = true;
                let res = await api.post(`/admin/location/statistics`, {
                    id: this.locationId,
                    period: this.period
                });
                this.loadingStats = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.statistics = res.data.data;
            }
            catch(error) {
                this.loadingStats = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        toMoney(v) {
            return _st.toMoney(v);
        },
        toPhone(v) {
            return _st.toPhoneNumber(v);
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            if( d == null )
                return '';

            return moment(d).format(f);
        },
        cleanObject() {
            return {
                id: 0,
                name: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipcode: "",
                adminContactName: "",
                adminContactPhone: "",
                adminContactEmail: "",
                athmovilPublicToken: "",
                athmovilActive: true,
                stripePK: "",
                blockMissingPayment: false,
                active: true,
                totalUnits: 0,
                workingHours: [],
                units: [],
                unitSummary: [],
                account: {},
                billing: {
                    cards: []
                }
            };
        },
        async tokenCreated( token ) {
            let api = new API();

            try {
                this.addingCard = true;
                let res = await api.post('/admin/location/add-card', {
                    token: token.id
                });
                this.addingCard = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                // add new card to the list
                this.location.billing.cards.push( res.data.data );
                this.newCardForm = false;
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.addingCard = false;
            }
        },
        getCardBrandImg( brand ) {
            switch( brand.toLowerCase() ) {
                case 'visa':                return require('@/assets/images/visa.svg');
                case 'mastercard':          return require('@/assets/images/mastercard.svg');
                case 'discover':            return require('@/assets/images/discover.svg');
                case 'american express':    return require('@/assets/images/amex.svg');
                default:                    return '';
            }
        },
        isMethodSelected(card) {
            let found = this.location.billing.cards.find(p => {
                return p === card;
            });

            return found != undefined && found != null;
        },
        toggleSelectAll() {
            this.$nextTick(() => {
                if( this.allPaymentsSelected ) {
                    this.payment.applyTo = [];
                } else {
                    this.payment.applyTo = this.location.billing.availablePayments.slice();
                }
            });
        },
        toggleItem(item) {
            if( !this.isSelected(item) ) 
                this.payment.applyTo.push(item);
            else
                this.payment.applyTo.splice( this.payment.applyTo.indexOf(item), 1);
        },
        isSelected(item) {
            let found = this.payment.applyTo.find(p => {
                return p === item;
            });

            return found != undefined && found != null;
        },
        async processPayment() {
            let api = new API();

            try {
                this.processingPayment = true;
                let res = await api.post('/admin/location/process-payment', {
                    card        : this.payment.method.id,
                    payments    : _st.extractProp( this.payment.applyTo, 'id' )
                });
                this.processingPayment = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.location.billing = res.data.data;
                this.location.account.paymentsBehind = res.data.data.availablePayments.length;
                this.paymentDialog = false;
                this.mxShowMessage( 'Payment applied', 'success' );
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.processingPayment = false;
            }
        },
        async blockLocation( blockLocation ) {
            let api = new API();

            try {
                this.blocking = true;
                let res = await api.post('/admin/block-location', {
                    locationId: this.locationId,
                    block: blockLocation
                });
                this.blocking = false;

                if( res.data.status == false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.location.blockMissingPayment = blockLocation;
                this.blockDialog = false;
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.blocking = false;
            }
        },
        async activate() {
            let api = new API();

            try {
                let res = await api.post('/admin/activate', {
                    locationId: this.locationId,
                    active: !this.location.active
                });

                if( res.data.status == false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.location.active = !this.location.active;
                this.toggleDialog = false;
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        createCard() {
            this.$refs.stripeCard.submit();
        },
        async postComment() {
            if( _st.isNUE( this.comment ) ) {
                this.mxShowMessage('Comment cannot not be empty', 'warning');
                return;
            }

            let api = new API();

            try {
                this.posting = true;
                let res = await api.post('/admin/location/comment', { id: this.locationId, comment: this.comment });
                this.posting = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.comments.push( res.data.data );
                this.comment = '';
            }
            catch(error) {
                this.posting = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        editUser( user ) {
            this.userDialog = true;
            let userObj = JSON.parse( JSON.stringify( user ) );

            if( _st.isNUE( this.$refs.user ) ) {
                setTimeout(() => this.$refs.user.load( userObj ), 500);
            }
            else {
                this.$refs.user.load( userObj );
            }
        },
        async saveUser() {
            try {
                this.savingUser = true;
                await this.$refs.user.save();
                this.savingUser = false;
            }
            catch(error) {
                this.savingUser = false;
                this.mxShowMessage(error, 'error');
            }
        },
        closeUserDialog(user) {
            this.userDialog = false;
            this.$refs.user.cleanObject();

            let exist = this.location.users.find(u => u.id == user.id);
            if( _st.isNUE( exist ) )
                this.location.users.push(user);
            else {
                let ix = this.location.users.findIndex(u => u.id == user.id);
                if( !_st.isNU( ix ) ) {
                    this.location.users[ix].firstName   = user.firstName;
                    this.location.users[ix].lastName    = user.lastName;
                    this.location.users[ix].email       = user.email;
                    this.location.users[ix].phone       = user.phone;
                    this.location.users[ix].privileges  = user.privileges;
                    this.location.users[ix].active      = user.active;
                    this.location.users[ix].locations   = user.locations;
                }
            }
        },
        isUserLocationActive( user ) {
            let ix = user.locations.findIndex(l => l.locationId == this.locationId);
            
            if( ix != -1 )
                return user.locations[ix].active;

            return false;
        }
    },
    components: {
        StripeElementCard, AdminUser
    }
    // Usar un componente para la parte de billing al location
}
</script>

<style lang="scss" scoped>
.bg-white {
    background-color: #fff;
    border-radius: 5px;
}
.v-tab, .v-btn {
    text-transform: none;
}
</style>