<template>
    <div>
        <v-form ref="form">
            <!-- <v-row no-gutters>
                <v-col cols="6"> -->
                    <div class="subheading">General Information</div>

                    <v-text-field v-model="user.email" type="email" label="Email" :rules="[rules.required, rules.isEmail]"></v-text-field>
                    <v-text-field v-model="user.firstName" label="First Name" :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="user.lastName" label="Last Name" :rules="[rules.required]"></v-text-field>
                    <v-text-field v-model="user.phone" type="tel" label="Phone" v-mask="'(###) ###-####'" :rules="[rules.required, rules.isPhone]"></v-text-field>
                    <v-checkbox :value="active" @change="toggleActiveStatus" label="Active"></v-checkbox>

                    <div v-if="showPrivileges">
                        <div class="subheading">Privileges</div>

                        <v-checkbox v-model="user.privileges.superAdmin" label="Super admin" hide-details />
                        <v-checkbox v-model="user.privileges.manageCommunities" label="Can manage communities" 
                            :disabled="!user.privileges.superAdmin" hide-details class="indent" />
                        <v-checkbox v-model="user.privileges.manageReports" label="Can generate reports" 
                            :disabled="!user.privileges.superAdmin" hide-details class="indent" />
                        <v-checkbox v-model="user.privileges.manageUsers" label="Can manage users" 
                            :disabled="!user.privileges.superAdmin" hide-details class="indent" />
                    </div>
                <!-- </v-col>
            </v-row> -->
        </v-form>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    props: {
        userId          : { type: [String, Number], default: 0 },
        locationId      : { type: [String, Number], default: 0 },
        showPrivileges  : { type: Boolean, default: false }
    },
    data() {
        return {
            user        : this.cleanObject(),
            loading     : false,

            rules       : {
                required    : v => !!v || 'required',
                isEmail     : v => _st.isEmail(v) || 'must be a valid email',
                isPhone     : v => _st.isPhone(v) || 'must be a valid phone'
            }
        }
    },
    computed: {
        active() {
            let ix = this.user.locations.findIndex(l => l.locationId == this.locationId);
            if( ix != -1 )
                return this.user.locations[ix].active;

            return false;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if( _st.isEmpty( this.userId ) )
                return;
        },
        async save() {
            if( !this.$refs.form.validate() )
                return;

            let api = new API();

            try {
                this.saving = true;
                let res = await api.post('/admin/user', { user: this.user, locationId: this.locationId, locationActive: this.active });
                this.saving = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.user.id = res.data.data.id;
                this.user.firstName = res.data.data.firstName;
                this.user.lastName = res.data.data.lastName;
                this.user.email = res.data.data.email;
                this.user.phone = res.data.data.phone;
                this.user.privileges = res.data.data.privileges;
                this.user.active = res.data.data.active;
                this.user.locations = res.data.data.locations;
                this.$emit('saved', res.data.data);
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage(error, 'error');
            }
        },
        load( user ) {
            this.user.id = user.id;
            this.user.firstName = user.firstName;
            this.user.lastName = user.lastName;
            this.user.email = user.email;
            this.user.phone = _st.toPhoneNumber( user.phone );
            this.user.privileges = user.privileges;
            this.user.active = user.active;
            this.user.locations = user.locations;
        },
        cleanObject() {
            return {
                id          : this.userId,
                firstName   : '',
                lastName    : '',
                email       : '',
                phone       : '',
                privileges  : [],
                active      : false,
                locations   : []
            };
        },
        toggleActiveStatus() {
            let ix = this.user.locations.findIndex(l => l.locationId == this.locationId);
            if( ix != -1 )
                this.user.locations[ix].active = !this.user.locations[ix].active;
        }
    },
    directives: {
        mask
    }
}
</script>

<style lang="scss" scoped>
.subheading {
    font-size: 12px;
    padding: 8px 0;
}
.indent {
    margin-left: 30px;
}
</style>